<template>
    <div>
        
        <v-card elevation="0">
            <validation-observer ref="observer">
                <v-form class="mt-4">
                    <v-container class="pt-5">
                        <v-row>
                            <v-col md="2" class="d-none d-md-block" />
                            <v-col md="8" sm="12" cols="12">
                                <v-row>
                                    <v-col md="8" sm="12" cols="12">
                                        <validation-provider
                                            v-slot="{ errors }"
                                            vid="descricao"
                                        >
                                            <v-text-field
                                                v-model="feriadoNovo.descricao"
                                                outlined
                                                dense
                                                :readonly="readonly"
                                                hide-details="auto"
                                                :disabled="$store.state.layout.loading"
                                                label="Nome"
                                                :error-messages="errors"
                                            />
                                        </validation-provider>
                                    </v-col>
                                    <v-col md="2" sm="6">
                                        <validation-provider
                                            v-slot="{ errors }"
                                            vid="ano"
                                        >
                                            <v-text-field
                                                v-model="feriadoNovo.ano"
                                                outlined
                                                :readonly="readonly"
                                                dense
                                                v-mask="'####'"
                                                hide-details="auto"
                                                :disabled="$store.state.layout.loading"
                                                label="Ano"
                                                :error-messages="errors"
                                            />
                                        </validation-provider>
                                    </v-col>
                                    <v-col md="2" sm="6">
                                        <validation-provider
                                            v-slot="{ errors }"
                                            vid="dia_mes"
                                        >
                                            <v-text-field
                                                v-model="feriadoNovo.dia_mes"
                                                outlined
                                                dense
                                                v-mask="'##/##'"
                                                :readonly="readonly"
                                                hide-details="auto"
                                                :disabled="$store.state.layout.loading"
                                                label="Dia/Mês"
                                                :error-messages="errors"
                                            />
                                        </validation-provider>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                        md="6"
                                        sm="6"
                                        class="d-flex justify-center"
                                    >
                                        <v-switch
                                            v-model="feriadoNovo.ativo"
                                            class="mt-0 pt-0"
                                            :readonly="readonly"
                                            :disabled="$store.state.layout.loading"
                                            :label="descricaoAtivo"
                                        />
                                    </v-col>
                                    <v-col
                                        md="6"
                                        sm="6"
                                        class="d-flex justify-center"
                                    >
                                        <v-switch
                                            v-model="feriadoNovo.fixo"
                                            class="mt-0 pt-0"
                                            :readonly="readonly"
                                            :disabled="$store.state.layout.loading"
                                            label="Fixo"
                                        />
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col md="2" class="d-none d-md-block" />
                        </v-row>
                    </v-container>
                </v-form>
            </validation-observer>
            <v-divider class="mt-4" />
            <v-card-actions>
                <v-row>
                    <v-col cols="12" class="text-left">
                        <btn-salvar
                            :temPermissao="
                                $store.getters['usuario/temPermissao'](
                                    'editar.tabelas'
                                )
                            "
                            :disabled="salvarDisabled"
                            :carregando="carregandoSalvar"
                            @click="$emit('salvar', feriadoNovo)"
                        />
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
export default {
    components: {},

    props: {
        validacaoFormulario: {
            type: Object,
            default: () => {
                return {};
            },
        },
        carregandoSalvar: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
   
        salvarDisabled: {
            type: Boolean,
            default: false,
        },
        feriado: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },

    data() {
        return {
            feriadoNovo: {
                descricao: null,
                ativo: true,
                fixo: false,
                dia_mes: null,
                ano: null,
            },
        };
    },
    computed: {
        descricaoAtivo() {
            return this.feriadoNovo.ativo ? "Ativo" : "Inativo";
        },
    },
    watch: {
        validacaoFormulario(val) {
            if (!val) return;

            return this.$refs.observer.setErrors(val);
        },

        feriado(feriado) {
            this.feriadoNovo = feriado;
            console.log(feriado, "feriado");
        },
    },
};
</script>
